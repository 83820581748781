import { SCHEDULE_TYPE } from "@/shared/interface/newItem";
import http from "@/shared/services/http.js";
import { actionsEnum, Auth, subjectsEnum } from "../auth";

export const scheduleService = {
  /**
   * Cancel a scheduled Work item (only for those upcoming WorkItem)
   * @param {SCHEDULE_TYPE} scheduleType: Once/Periodically
   * @param {string} scheduleId: the schedule_id of the WorkItem
   * @returns Promise
   */
  cancelScheduledWorkItem(
    scheduleType: SCHEDULE_TYPE,
    scheduleId: string
  ): Promise<any> {
    const authParams = Auth.addAuthParamstoRequest(
      actionsEnum.RUN,
      subjectsEnum.APPLICATION
    );
    if (scheduleType === SCHEDULE_TYPE.once) {
      return http.get(
        process.env.VUE_APP_BASE_WS_URL + "/cionb/case/cancel/job/" + scheduleId
      );
    } else {
      return http.delete(
        process.env.VUE_APP_BASE_WS_URL +
          "/cionb/case/scheduler/periodic/" +
          scheduleId,
        { params: JSON.stringify(authParams) }
      );
    }
  },
};
