var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item_list"},[_c('el-tabs',{attrs:{"type":"border-card"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{attrs:{"label":_vm.tabs[0],"name":_vm.tabs[0]}},[_c('div',{staticClass:"item_list_title"},[_vm._v("Upcoming Schedules")]),_c('table-component',{attrs:{"scheduledType":_vm.activeTab,"scheduleStatus":[_vm.scheduledStatus.Scheduled]}}),_c('div',{staticClass:"item_list_title history"},[_vm._v("Scheduled History")]),_c('table-component',{attrs:{"scheduledType":_vm.activeTab,"scheduleStatus":[
          _vm.scheduledStatus.ScheduleSuccess,
          _vm.scheduledStatus.ScheduleFailed,
          _vm.scheduledStatus.Canceled,
        ]}})],1),_c('el-tab-pane',{attrs:{"label":_vm.tabs[1],"name":_vm.tabs[1]}},[_c('div',{staticClass:"item_list_title"},[_vm._v("Upcoming/Ongoing Schedules")]),_c('table-component',{attrs:{"scheduledType":_vm.activeTab,"tableHeader":_vm.PeriodicTableHeader,"scheduleStatus":[_vm.scheduledStatus.Scheduled]}}),_c('div',{staticClass:"item_list_title history"},[_vm._v("Scheduled History")]),_c('table-component',{attrs:{"scheduledType":_vm.activeTab,"tableHeader":_vm.PeriodicTableHeader,"scheduleStatus":[
          _vm.scheduledStatus.Completed,
          _vm.scheduledStatus.Canceled,
        ],"tableType":['periodically_history']}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }