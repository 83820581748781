
import "@/assets/styles/ItemScheduledPage.scss";
import { Component, Vue } from "vue-property-decorator";
import TableComponent from "@/shared/components/ItemScheduledListComponent/Table.vue";
import {
  SCHEDULE_STATUS,
  periodicTableHeader,
} from "@/shared/interface/scheduledItem";
import { SCHEDULE_TYPE } from "@/shared/interface/newItem";

@Component({
  name: "ScheduleItemListPage",
  components: {
    TableComponent,
  },
})
export default class ScheduleItemListPage extends Vue {
  activeTab: SCHEDULE_TYPE | string | (string | null)[] = SCHEDULE_TYPE.once;
  tabs = [SCHEDULE_TYPE.once, SCHEDULE_TYPE.periodically];
  scheduledStatus = SCHEDULE_STATUS;
  PeriodicTableHeader = periodicTableHeader();

  created(): void {
    if (this.$route.query?.scheduleType)
      this.activeTab = this.$route.query.scheduleType;
  }
}
